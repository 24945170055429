export const profileQuestions = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'businessName',
    label: 'Business Name',
  },
  {
    key: 'clientReferenceNumber',
    label: 'Client Reference Number',
  },
  {
    key: 'businessPartnerDetails',
    label: 'Business Partner Details',
  },
  {
    key: 'amoutInvestedToDate',
    label: 'Amount Invested To Date',
  },
  {
    key: 'amountPlannedToBeInvested',
    label: 'Amount Planned To Be Invested',
  },
  {
    key: 'ownFunding',
    label: 'Own Funding?',
  },
  {
    key: 'annualResearchDevelopmentAmount',
    label: 'Annual Research & Development Spend In The Last Complete Financial Year',
  },
  {
    key: 'processInnovation',
    label: 'Number Of Product And Process Innovations In The Last Three Financial Years, And Future Plans (if applicable)',
  },
  {
    key: 'communityInvolvement',
    label: 'Are You Actively Involved In Any Forums Or Networks?',
  },
  {
    key: 'currentEmployeeDetails',
    label: 'Current Employee Details',
  },
  {
    key: 'employeeOpportunity',
    label: 'Does Taking On An Employee Present An Opportunity For Your Business?',
  },
  {
    key: 'courseInterest',
    label: 'Course Interest',
  }
];