import PropTypes from "prop-types";
import React from "react";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />
      <h1 className="welcome">Start and Grow Diagnostic Tool</h1>
      <main className="flex flex-col flex-1 max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        {children}
      </main>

      <footer className="bg-primary text-white bg-footer">
        <nav className="max-w-4xl mx-auto p-4 md:p-8 text-sm text-center">
          <ul className="text-lg list-none inline-menu font-light">
              <li>
                <a
                  href="http://www.facebook.com/UoGStartandGrow"
                  target="_blank"
                >
                  <FaFacebookF className="inline"/>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/start-and-grow-enterprise/"
                  target="_blank"
                >
                  <FaLinkedin className="inline"/>
                </a>
              </li>
              <li>
                <a
                  href="http://www.twitter.com/UoGStartandGrow"
                  target="_blank"
                >
                  <FaTwitter className="inline"/>
                </a>
              </li>
          </ul>
          <ul className="text-lg list-none inline-menu inline-menu-mobblock font-light">
            <li>© 2020 Start and Grow Enterprise</li>
            <li>
              <a
                href="https://www.startandgrowenterprise.uk/privacy-policy/"
                data-id="dropdown-menu-94"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.startandgrowenterprise.uk/cookie-policy/"
                data-id="dropdown-menu-95"
              >
                Cookie Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.startandgrowenterprise.uk/terms-and-conditions/"
                data-id="dropdown-menu-96"
              >
                Terms and Conditions
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
