import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import Logo from "./logo";
import ERDF from "./erdf";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-white">
      <div className="flex flex-wrap items-center justify-between mx-auto px-4 md:px-8 py-2 shadow-md">
        <Link className="flex items-center no-underline text-primary max-w-11" to="/">
          <div className="mr-5">
            <Logo />
          </div>
          <div className="hidden md:block">
            <ERDF />
          </div>
        </Link>

        <button
          className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-primary"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
          ].map(link => (
            <Link
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline hover:text-primary transition-underline-primary py-2 px-3"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
          <a
            href="https://www.startandgrowenterprise.uk/"
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white bg-primary hover:bg-secondary transition-bg py-2 px-3 rounded-md"
            >
              Back
            </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
